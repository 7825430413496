<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-row>
      <v-col cols="12" sm="12">
        <BaseCard>
          <OpexFormulario
            :idTipoOpex="idTipoOpex"
            :tipoOpex="tipoOpex"
            :nombreTipoOpex="nombreTipoOpex"
            :newVersion="1"
          >
          </OpexFormulario>
        </BaseCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'OpexTIFormNuevaVersion',
  components: {
    OpexFormulario: () => import('../../components/OpexFormulario')
  },
  data: () => ({
    title: 'NUEVA VERSIÓN OPEX TI',
    page: {
      title: 'Nueva Versión OPEX TI'
    },
    breadcrumbs: [
      {
        text: 'Presupuesto',
        disabled: true
      },
      {
        text: 'OPEX TI',
        disabled: true
      },
      {
        text: 'Nueva Versión',
        disabled: false
      }
    ],
    idTipoOpex: 5,
    tipoOpex: 'ti',
    nombreTipoOpex: 'TI'
  }),
  methods: {
    open() {},
    close() {}
  },
  created() {}
};
</script>
